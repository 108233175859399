import { JSX } from 'react';

export enum PageType {
  INSTITUTION = 'INSTITUTION',
  DEPARTMENT = 'DEPARTMENT',
  DEPARTMENT_SECTION = 'DEPARTMENT_SECTION',
  PERSONNEL = 'PERSONNEL',
  SCHEDULE = 'SCHEDULE',
  USER = 'USER',
  PERSONNEL_ON_DUTY = 'PERSONNEL_ON_DUTY',
  PERSONNEL_PERFORMANCES = 'PERSONNEL_PERFORMANCES',
}

export interface Page {
  pageType: PageType;
  name: string;
  path: string;
  disabled: boolean;
  component: JSX.Element;
}
