import trLocale from 'date-fns/locale/tr';
import enLocale from 'date-fns/locale/en-US';
import { TFunction } from 'i18next';
import { DateTime, Info } from 'luxon';
import { Language } from '../interfaces/Language';
import { getStoredLanguage } from './Language';
import { FullDayDates } from '../interfaces/FullDayDates';
import { DateRange } from '../interfaces/DateRange';

export const formatSingleDay = (t: TFunction, date: Date): string =>
  DateTime.fromJSDate(date)
    .setLocale(getStoredLanguage())
    .toFormat(t('date.single_day_format'));

export const formatLocalTime = (t: TFunction, date: Date): string =>
  DateTime.fromJSDate(date)
    .setLocale(getStoredLanguage())
    .toFormat(t('date.local_time_format'));

export const getDatePickerLocale = () => {
  switch (getStoredLanguage()) {
    case Language.TR:
      return trLocale;
    default:
      return enLocale;
  }
};

export const getFirstDayOfCurrentMonth = (): Date => {
  const currentDate = new Date();
  return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
};

export const getLastDayOfMonth = (month = new Date()): Date => {
  return new Date(month.getFullYear(), month.getMonth() + 1, 0);
};

export const getNextDay = (d: Date): Date =>
  DateTime.fromJSDate(d).plus({ day: 1 }).toJSDate();

export const getAllDatesBetweenTwoDate = (
  startDate: Date,
  endDate: Date,
): Date[] => {
  const dates: Date[] = [];

  for (
    let d = new Date(startDate.getTime());
    d <= endDate;
    d.setDate(d.getDate() + 1)
  ) {
    dates.push(new Date(d.getTime()));
  }
  return dates;
};

export const getCurrentYear = (): number => new Date().getFullYear();

export const dateRangeContainsYear = (
  dateRange: DateRange,
  year: number,
): boolean =>
  year >= dateRange.startAt.getFullYear() &&
  year <= dateRange.endAt.getFullYear();

export const dateRangeContainsMonth = (
  dateRange: DateRange,
  month: number,
): boolean => {
  if (
    (dateRange.startAt.getMonth() <= month &&
      dateRange.startAt.getFullYear() <= dateRange.endAt.getFullYear()) ||
    (dateRange.endAt.getMonth() >= month &&
      dateRange.startAt.getFullYear() <= dateRange.endAt.getFullYear())
  ) {
    if (dateRange.startAt.getFullYear() === dateRange.endAt.getFullYear()) {
      return (
        dateRange.startAt.getMonth() <= month &&
        dateRange.endAt.getMonth() >= month
      );
    }
    return true;
  }

  return false;
};

export const getMonthNames = (): string[] =>
  Info.months('long', { locale: getStoredLanguage() });

export const isHoliday = (
  date: Date,
  fullDays: FullDayDates[],
  is4d: boolean = false,
): boolean => {
  if ((!isWorkday(date) && !is4d) || (is4d && isSunday(date))) return true;

  // eslint-disable-next-line no-restricted-syntax
  for (const fd of fullDays) {
    if (fd.isOfficialHoliday) {
      for (
        let d = new Date(fd.startAt.getTime());
        d <= fd.endAt;
        d.setDate(d.getDate() + 1)
      ) {
        if (d.getTime() === date.getTime()) return true;
      }
    }
  }
  return false;
};

export const isWorkday = (date: Date): boolean => {
  const dayOfWeek = date.getDay();
  return dayOfWeek !== 0 && dayOfWeek !== 6;
};

export const isSunday = (date: Date): boolean => {
  const dayOfWeek = date.getDay();
  return dayOfWeek === 0;
};

export const removeTimezone = (date: Date) => {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  );
};

export const areAdjacentDays = (date1: Date, date2: Date) => {
  const oneDayInMs = 24 * 60 * 60 * 1000;
  const diffInMs = Math.abs(date1.getTime() - date2.getTime());
  return diffInMs === oneDayInMs;
};
