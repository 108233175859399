import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { sharedColors, sharedStyles } from '../../utils/Style';
import { areAdjacentDays, formatLocalTime } from '../../utils/Date';
import { Schedule, ScheduleSummary } from '../../interfaces/Schedule';

interface ScheduleCombineDialogProps {
  schedule: Schedule;
  scheduleSummaries: ScheduleSummary[];
  onClose: (id: number, b: boolean, n: string) => void;
  onCancel: () => void;
}

export const ScheduleCombineDialog = (props: ScheduleCombineDialogProps) => {
  const { t } = useTranslation();

  const [possibleSchedules] = useState<ScheduleSummary[]>(
    props.scheduleSummaries.filter(
      (s) =>
        s.departmentSectionID === props.schedule.departmentSection.ID! &&
        (areAdjacentDays(s.startAt, props.schedule.endAt) ||
          areAdjacentDays(s.endAt, props.schedule.startAt)),
    ),
  );

  const [selectedScheduleID, setSelectedScheduleID] = useState<number>(-1);
  const [keepOldPlans, setKeepOldPlans] = useState<boolean>(true);
  const [name, setName] = useState<string>('');

  return (
    <Dialog open={true} onClose={props.onCancel}>
      <DialogTitle>Planları Birleştir</DialogTitle>
      <DialogContent>
        <FormControl size='small' sx={{ width: 250, mt: 2 }}>
          <InputLabel>{'Uygun Planlar'}</InputLabel>
          <Select
            value={selectedScheduleID}
            label={'Uygun Planlar'}
            onChange={(e: any) =>
              setSelectedScheduleID(e.target.value as number)
            }
            name='select'
          >
            <MenuItem value={-1}>-</MenuItem>
            {possibleSchedules.map((schedule) => (
              <MenuItem value={schedule.ID} key={schedule.ID}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>{schedule.name}</Typography>
                  {selectedScheduleID !== schedule.ID && (
                    <Typography
                      sx={{
                        ...sharedStyles.subtitle2,
                        color: sharedColors.gray4,
                      }}
                    >
                      {t('schedule.previous_schedule_subtitle', {
                        date: formatLocalTime(
                          t,
                          schedule.audit.lastUpdatedAt ??
                            schedule.audit.createdAt,
                        ),
                      })}
                    </Typography>
                  )}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          sx={{ ml: 2, mt: 2 }}
          label={'Yeni Plan Adı'}
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
          size='small'
        />
        <FormControlLabel
          disabled={selectedScheduleID === -1}
          sx={{ mt: 1.5 }}
          control={
            <Checkbox
              checked={keepOldPlans}
              onChange={() => setKeepOldPlans(!keepOldPlans)}
            />
          }
          label={
            <Typography sx={sharedStyles.subtitle2}>
              {'Eski Planları Tut'}
            </Typography>
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onCancel}
          sx={sharedStyles.buttonText}
        >
          {t('shared.cancel')}
        </Button>
        <Button
          variant='contained'
          disabled={selectedScheduleID === -1}
          onClick={() => props.onClose(selectedScheduleID, keepOldPlans, name)}
          color='primary'
          sx={sharedStyles.buttonText}
        >
          Planları Birleştir
        </Button>
      </DialogActions>
    </Dialog>
  );
};
