import { Shift } from '../interfaces/Shift';

export const defaultAlgorithmParameters = {
  non_fullday_night: 35000,
  fullday_night: -75000,
  worked_yesterday_night: 400000,
  worked_yesterday_full_time: 300000,
  worked_day_before_full_time: 10000,
  consecutive_spare_days: 20000,
  overtime_hours: 8.5,
  missing_hours: -5,
  has_pto: -30,
  overtime_range_weight: 1500,
  overtime_stdev_weight: 1600,
  day_night_range_weight: 1500,
  day_night_stdev_weight: 1500,
  weekend_range_weight: 5000,
  weekend_stdev_weight: 5000,
  mutated_individual_count: 350,
  crossed_individual_count: 60,
  termination_steps: 250,
  elite_survivors: 150,
  newbie_survivors: 75,
  full_day_available_personnels: 400000,
};

export const shiftOptions = [
  Shift.EMPTY,
  Shift.DAY,
  Shift.NIGHT,
  Shift.ALL_DAY,
  Shift.EIGHT_HOUR_NIGHT,
  Shift.SIXTEEN_HOUR_DAY,
];

export const occupiedShifts = [Shift.DAY, Shift.NIGHT, Shift.ALL_DAY];
