import { Page, PageType } from '../interfaces/Page';
import DepartmentPage from '../components/Department/DepartmentPage';
import PersonnelPage from '../components/Personnel/PersonnelPage';
import SchedulePage from '../components/Schedule/SchedulePage';
import DepartmentSectionPage from '../components/DepartmentSection/DepartmentSectionPage';
import InstitutionPage from '../components/Institution/InstitutionPage';
import { TokenData } from '../interfaces/TokenData';
import { hasInstitutionAccess, hasWriteAccess } from '../utils/TokenData';
import { AccessLevel } from '../interfaces/User';
import UserPage from '../components/User/UserPage';
import PersonnelOnDutyPage from '../components/PersonnelOnDuty/PersonnelOnDutyPage';
import PersonnelPerformancePage from '../components/PersonnelPerformance/PersonnelPerformancePage';

export const getAvailablePages = (tokenData: TokenData): Page[] => [
  {
    pageType: PageType.INSTITUTION,
    name: 'page.institution',
    path: '/institution',
    disabled: false,
    component: <InstitutionPage />,
  },
  {
    pageType: PageType.DEPARTMENT,
    name: 'page.department',
    path: '/department',
    disabled: false,
    component: <DepartmentPage />,
  },
  {
    pageType: PageType.DEPARTMENT_SECTION,
    name: 'page.department_section',
    path: '/department-section',
    disabled: false,
    component: <DepartmentSectionPage />,
  },
  {
    pageType: PageType.PERSONNEL,
    name: 'page.personnel',
    path: '/personnel',
    disabled: false,
    component: <PersonnelPage />,
  },
  {
    pageType: PageType.SCHEDULE,
    name: 'page.schedule',
    path: '/schedule',
    disabled: false,
    component: <SchedulePage />,
  },
  {
    pageType: PageType.PERSONNEL_PERFORMANCES,
    name: 'page.personnel_performances',
    path: '/personnel-performances',
    disabled: false,
    component: <PersonnelPerformancePage />,
  },
  {
    pageType: PageType.PERSONNEL_ON_DUTY,
    name: 'page.personnel_on_duty',
    path: '/personnel-on-duty',
    disabled: !hasInstitutionAccess(tokenData),
    component: <PersonnelOnDutyPage />,
  },
  {
    pageType: PageType.USER,
    name: 'page.user',
    path: '/user',
    disabled: !hasWriteAccess(tokenData, AccessLevel.INSTITUTION),
    component: <UserPage />,
  },
];
