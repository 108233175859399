import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UploadIcon from '@mui/icons-material/Upload';
import { isFinite } from 'lodash';
import { toast } from 'material-react-toastify';
import { useRecoilValue } from 'recoil';
import { Pagination } from '../../interfaces/Pagination';
import { sharedColors, sharedStyles } from '../../utils/Style';
import UpsertPersonnelDialog from './UpsertPersonnelDialog';
import { Personnel, PersonnelPosition } from '../../interfaces/Personnel';
import { Department } from '../../interfaces/Department';
import {
  comparePersonnels,
  i18StringFromPosition,
} from '../../utils/Personnel';
import { deletePersonnel } from '../../services/Personnel';
import Waiting from '../Waiting';
import ConfirmDialog from '../ConfirmDialog';
import DepartmentAutocomplete from '../Department/DepartmentAutocomplete';
import PersonnelPositionDropdown from './PersonnelPositionDropdown';
import { SortCriteria } from '../../interfaces/SortCriteria';
import { getPriorityByOrder } from '../../utils/Sort';
import SortableTableCell from '../SortableTableCell';
import { DepartmentSection } from '../../interfaces/DepartmentSection';
import DepartmentSectionAutocomplete from '../DepartmentSection/DepartmentSectionAutocomplete';
import { formatToLimitedDecimals } from '../../utils/Decimals';
import { Institution } from '../../interfaces/Institution';
import InstitutionAutocomplete from '../Institution/InstitutionAutocomplete';
import { tokenDataAtom } from '../../atoms/TokenData';
import { hasWriteAccess } from '../../utils/TokenData';
import { AccessLevel } from '../../interfaces/User';
import UploadPersonnelDialog from './UploadPersonnelDialog';

interface PersonnelTableProps {
  personnels: Personnel[];
  institutions: Institution[];
  departments: Department[];
  onChange: () => void;
}

const PersonnelTable = (props: PersonnelTableProps) => {
  const { t } = useTranslation();

  const tokenData = useRecoilValue(tokenDataAtom);

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<Pagination>({
    pageNumber: 0,
    pageSize: 15,
  });
  const [searchedValue, setSearchedValue] = useState('');
  const [searchedInstitution, setSearchedInstitution] =
    useState<Institution | null>(null);
  const [searchedDepartment, setSearchedDepartment] =
    useState<Department | null>(null);
  const [searchedDepartmentSection, setSearchedDepartmentSection] =
    useState<DepartmentSection | null>(null);
  const [searchedPosition, setSearchedPosition] = useState(
    PersonnelPosition.ALL,
  );
  const [clickedPersonnel, setClickedPersonnel] = useState<
    Personnel | undefined
  >(undefined);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [showNewPersonnelDialog, setShowNewPersonnelDialog] = useState(false);
  const [personnelToDelete, setPersonnelToDelete] = useState<
    Personnel | undefined
  >(undefined);
  const [sortCriteria, setSortCriteria] = useState<SortCriteria<Personnel>>({
    fieldName: 'name',
    asc: true,
  });

  useEffect(() => {
    setPagination({
      ...pagination,
      pageNumber: 0,
    });
  }, [
    searchedInstitution,
    searchedDepartment,
    searchedDepartmentSection,
    searchedPosition,
    searchedValue,
  ]);

  useEffect(() => {
    setSearchedDepartment(null);
  }, [searchedInstitution]);

  useEffect(() => {
    setSearchedDepartmentSection(null);
  }, [searchedDepartment]);

  const handleSearch = (e: any) => {
    setSearchedValue(e.target.value ?? '');
  };

  const handleAddPersonnel = () => {
    setShowNewPersonnelDialog(false);
    setClickedPersonnel(undefined);
    props.onChange();
  };

  const handleUploadPersonnel = () => {
    setShowUploadDialog(false);
    props.onChange();
  };

  const handleDeletePersonnel = () => {
    if (!isFinite(personnelToDelete?.ID)) {
      return;
    }

    setLoading(true);
    deletePersonnel(personnelToDelete!.ID!)
      .then(() => {
        toast.success(t('personnel.successful.delete'));
        setPersonnelToDelete(undefined);
        props.onChange();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleCloseUpsertPersonnelDialog = () => {
    setClickedPersonnel(undefined);
    setShowNewPersonnelDialog(false);
  };

  const getCanBeOnCallIcon = (canBeOnCall: boolean) =>
    canBeOnCall ? (
      <CheckCircleIcon sx={{ color: sharedColors.statusGreen }} />
    ) : (
      <CancelIcon sx={{ color: sharedColors.statusRed }} />
    );

  const handleSort = (fieldName: keyof Personnel) => {
    setSortCriteria({
      fieldName,
      asc: fieldName === sortCriteria.fieldName ? !sortCriteria.asc : true,
    });
  };

  let filteredRows = props.personnels.filter((personnel) =>
    personnel.name.toLowerCase().includes(searchedValue.toLowerCase()),
  );
  if (searchedInstitution) {
    filteredRows = filteredRows.filter(
      (personnel) => personnel.institutionID === searchedInstitution.ID,
    );
  }
  if (searchedDepartment) {
    filteredRows = filteredRows.filter(
      (personnel) => personnel.departmentName === searchedDepartment.name,
    );
  }
  if (searchedDepartmentSection) {
    filteredRows = filteredRows.filter(
      (personnel) =>
        personnel.departmentSectionName === searchedDepartmentSection.name ||
        personnel.position === PersonnelPosition.HEAD_NURSE,
    );
  }
  if (searchedPosition !== PersonnelPosition.ALL) {
    filteredRows = filteredRows.filter(
      (personnel) => personnel.position === searchedPosition,
    );
  }
  filteredRows = filteredRows.sort((p1, p2) =>
    comparePersonnels(p1, p2, sortCriteria.fieldName) < 0
      ? getPriorityByOrder(sortCriteria.asc)
      : -getPriorityByOrder(sortCriteria.asc),
  );

  const paginatedRows = filteredRows.slice(
    pagination.pageNumber * pagination.pageSize,
    (pagination.pageNumber + 1) * pagination.pageSize,
  );

  const readOnly = !hasWriteAccess(tokenData, AccessLevel.DEPARTMENT_SECTION);

  return (
    <Box component='div' sx={sharedStyles.outerTableContainer}>
      <Waiting open={loading} />
      <ConfirmDialog
        content={t('personnel.delete.confirm_message')}
        open={!!personnelToDelete}
        onClose={() => setPersonnelToDelete(undefined)}
        onConfirm={handleDeletePersonnel}
        color='error'
      />
      <UpsertPersonnelDialog
        open={showNewPersonnelDialog || !!clickedPersonnel}
        clickedPersonnel={clickedPersonnel}
        onClose={handleCloseUpsertPersonnelDialog}
        onUpsert={handleAddPersonnel}
        institutions={props.institutions}
        departments={props.departments}
      />
      <UploadPersonnelDialog
        open={showUploadDialog}
        institutions={props.institutions}
        departments={props.departments}
        onClose={() => setShowUploadDialog(false)}
        onUpload={handleUploadPersonnel}
      />
      <Typography
        sx={{
          ...sharedStyles.h5,
          color: sharedColors.black,
          fontWeight: 450,
        }}
      >
        {t('personnel.title')}
      </Typography>
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'row',
          flexWrap: 'wrap',
          mt: 1.5,
        }}
      >
        <TextField
          id='search-text-field'
          size='small'
          label={t('shared.search')}
          variant='outlined'
          value={searchedValue}
          onChange={handleSearch}
          sx={{ width: 230, backgroundColor: sharedColors.white, mr: 1.5 }}
        />
        <Box component='div' sx={{ mr: 1.5 }}>
          <InstitutionAutocomplete
            label={t('personnel.institution') as string}
            width={300}
            options={props.institutions}
            value={searchedInstitution}
            onChange={setSearchedInstitution}
            backgroundColor={sharedColors.white}
          />
        </Box>
        <DepartmentAutocomplete
          label={t('personnel.department') as string}
          disabled={!searchedInstitution}
          options={props.departments.filter(
            (department) =>
              !!searchedInstitution &&
              department.institutionID === searchedInstitution.ID,
          )}
          value={searchedDepartment}
          onChange={setSearchedDepartment}
          width={230}
          backgroundColor={sharedColors.white}
        />
        <Box component='div' sx={{ mr: 1.5 }} />
        <DepartmentSectionAutocomplete
          label={t('personnel.department_section') as string}
          options={searchedDepartment?.departmentSections ?? []}
          value={searchedDepartmentSection}
          disabled={!searchedDepartment}
          onChange={setSearchedDepartmentSection}
          width={230}
          backgroundColor={sharedColors.white}
        />
        <Box component='div' sx={{ mr: 1.5 }} />
        <PersonnelPositionDropdown
          label={t('personnel.position') as string}
          backgroundColor={sharedColors.white}
          allowSelectAll
          required
          value={searchedPosition}
          onChange={setSearchedPosition}
          width={230}
        />
        <Box component='div' sx={{ flexGrow: 1 }} />
        {!readOnly && [
          <Button
            variant='contained'
            color='primary'
            startIcon={<UploadIcon />}
            onClick={() => setShowUploadDialog(true)}
            sx={{ ...sharedStyles.buttonText, mt: 'auto', mb: 'auto', mr: 1.5 }}
          >
            {t('personnel.upload')}
          </Button>,
          <Button
            variant='contained'
            color='primary'
            onClick={() => setShowNewPersonnelDialog(true)}
            sx={{ ...sharedStyles.buttonText, mt: 'auto', mb: 'auto' }}
          >
            {t('personnel.new')}
          </Button>,
        ]}
      </Box>
      <TableContainer component={Paper} sx={sharedStyles.tableContainer}>
        <Table size='small'>
          <TableHead sx={{ backgroundColor: sharedColors.gray2 }}>
            <TableRow>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='name'
                onSort={() => handleSort('name')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('personnel.name')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='position'
                onSort={() => handleSort('position')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('personnel.position')}
                </Typography>
              </SortableTableCell>
              <TableCell align='center'>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('personnel.can_be_on_call')}
                </Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography sx={sharedStyles.columnLabel}>4D</Typography>
              </TableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='dayShiftHours'
                onSort={() => handleSort('dayShiftHours')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('personnel.day_shift_hours')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='institutionName'
                onSort={() => handleSort('institutionName')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('personnel.institution')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='departmentName'
                onSort={() => handleSort('departmentName')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('personnel.department')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='departmentSectionName'
                onSort={() => handleSort('departmentSectionName')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('personnel.department_section')}
                </Typography>
              </SortableTableCell>
              {!readOnly && (
                <TableCell align='center'>
                  <Typography sx={sharedStyles.columnLabel}>
                    {t('shared.action')}
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((row) => (
              <TableRow key={row.ID} sx={sharedStyles.editableRow}>
                <TableCell onClick={() => setClickedPersonnel(row)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.name}
                  </Typography>
                </TableCell>
                <TableCell onClick={() => setClickedPersonnel(row)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {i18StringFromPosition(row.position)}
                  </Typography>
                </TableCell>
                <TableCell
                  align='center'
                  onClick={() => setClickedPersonnel(row)}
                >
                  {getCanBeOnCallIcon(row.canBeOnCall)}
                </TableCell>
                <TableCell
                  align='center'
                  onClick={() => setClickedPersonnel(row)}
                >
                  {getCanBeOnCallIcon(row.is4d)}
                </TableCell>
                <TableCell onClick={() => setClickedPersonnel(row)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {t('shared.n_hours', {
                      n: formatToLimitedDecimals(row.dayShiftHours),
                    })}
                  </Typography>
                </TableCell>
                <TableCell onClick={() => setClickedPersonnel(row)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.institutionName}
                  </Typography>
                </TableCell>
                <TableCell onClick={() => setClickedPersonnel(row)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.departmentName}
                  </Typography>
                </TableCell>
                <TableCell onClick={() => setClickedPersonnel(row)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.departmentSectionName}
                  </Typography>
                </TableCell>
                {!readOnly && (
                  <TableCell align='center'>
                    <Button
                      onClick={() => setPersonnelToDelete(row)}
                      color='secondary'
                      sx={{
                        ...sharedStyles.buttonText,
                        p: 0,
                        minWidth: 0,
                      }}
                    >
                      {t('shared.delete')}
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={filteredRows.length}
          page={pagination.pageNumber}
          onPageChange={(_, newPage) =>
            setPagination({ ...pagination, pageNumber: newPage })
          }
          rowsPerPage={pagination.pageSize}
          onRowsPerPageChange={(e) =>
            setPagination({
              pageNumber: 0,
              pageSize: parseInt(e.target.value, 10),
            })
          }
          rowsPerPageOptions={[5, 10, 15, 25, 50]}
          component='div'
          labelRowsPerPage={t('shared.pagination.rows_per_page')}
          labelDisplayedRows={({ from, to, count }) =>
            t('shared.pagination.from_to_count', { from, to, count })
          }
        />
      </TableContainer>
    </Box>
  );
};

export default PersonnelTable;
