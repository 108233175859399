import { DateTime } from 'luxon';
import { isFinite } from 'lodash';

export interface PersonnelPerformance {
  name: string;
  totalOvertime: number;
  totalPtoHours: number;
  institutionID: number;
  departmentID: number;
  departmentSectionID?: number;
  institutionName: string;
  departmentName: string;
  departmentSectionName?: string;
}

export const personnelPerformanceFromResponse = (
  responseData: any,
): PersonnelPerformance => ({
  name: responseData.name,
  totalOvertime: responseData.total_overtime,
  totalPtoHours: responseData.total_pto_hours,
  institutionID: responseData.institution_id,
  departmentID: responseData.department_id,
  departmentSectionID: responseData.department_section_id,
  institutionName: responseData.institution_name,
  departmentName: responseData.department_name,
  departmentSectionName: responseData.department_section_name,
});

export interface PersonnelPerformanceRequest {
  startAt: Date;
  endAt: Date;
  institutionID?: number;
  departmentID?: number;
  departmentSectionID?: number;
}

export const personnelPerformanceRequestOutputTransformer = (
  req: PersonnelPerformanceRequest,
) => {
  const params: any = {
    start_at: DateTime.fromJSDate(req.startAt).toISODate()!,
    end_at: DateTime.fromJSDate(req.endAt).toISODate()!,
  };

  if (isFinite(req.institutionID)) {
    params.institution_id = req.institutionID;
  }
  if (isFinite(req.departmentID)) {
    params.department_id = req.departmentID;
  }
  if (isFinite(req.departmentSectionID)) {
    params.department_section_id = req.departmentSectionID;
  }
  return params;
};
