import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isFinite } from 'lodash';
import { toast } from 'material-react-toastify';
import { useRecoilValue } from 'recoil';
import { ChevronRight } from '@mui/icons-material';
import { Pagination } from '../../interfaces/Pagination';
import { sharedColors, sharedStyles } from '../../utils/Style';
import InsertScheduleDialog from './InsertScheduleDialog';
import { Department } from '../../interfaces/Department';
import {
  Schedule,
  ScheduleGetRequest,
  ScheduleList,
  ScheduleSortableFields,
  ScheduleStatus,
  ScheduleSummary,
} from '../../interfaces/Schedule';
import UpdateScheduleDialog from './UpdateScheduleDialog';
import { getScheduleDateRange } from '../../utils/Schedule';
import { formatLocalTime, formatSingleDay } from '../../utils/Date';
import DepartmentAutocomplete from '../Department/DepartmentAutocomplete';
import SortableTableCell from '../SortableTableCell';
import { DepartmentSection } from '../../interfaces/DepartmentSection';
import DepartmentSectionAutocomplete from '../DepartmentSection/DepartmentSectionAutocomplete';
import DownloadMenu from '../DownloadMenu';
import MonthDropdown from '../MonthDropdown';
import YearDropdown from '../YearDropdown';
import { extractYearsFromDateRanges } from '../../utils/DateRange';
import { downloadScheduleExcel } from '../../services/Schedule';
import Waiting from '../Waiting';
import { Institution } from '../../interfaces/Institution';
import InstitutionAutocomplete from '../Institution/InstitutionAutocomplete';
import { tokenDataAtom } from '../../atoms/TokenData';
import { hasWriteAccess } from '../../utils/TokenData';
import { AccessLevel } from '../../interfaces/User';
import ScheduleStatusField from './ScheduleStatusField';
import { SortCriteria } from '../../interfaces/SortCriteria';
import { getScheduleSortRequest } from '../../utils/Sort';
import ScheduleStatusDropdown from './ScheduleStatusDropdown';

interface ScheduleTableProps {
  institutions: Institution[];
  scheduleList: ScheduleList;
  departments: Department[];
  scheduleSummaries: ScheduleSummary[];
  onChange: (pagination: Pagination) => void;
  refreshSchedules: (req: ScheduleGetRequest) => void;
}

const ScheduleTable = (props: ScheduleTableProps) => {
  const { t } = useTranslation();

  const tokenData = useRecoilValue(tokenDataAtom);

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<Pagination>({
    pageNumber: 0,
    pageSize: 10,
  });
  const [searchedName, setSearchedName] = useState('');
  const [getRequest, setGetRequest] = useState<ScheduleGetRequest>({
    pagination: { pageNumber: 0, pageSize: 10 },
    sort: {
      sortFieldName: ScheduleSortableFields.LAST_UPDATED_AT,
      isDescending: true,
    },
  });
  const [searchedInstitution, setSearchedInstitution] =
    useState<Institution | null>(null);
  const [searchedDepartment, setSearchedDepartment] =
    useState<Department | null>(null);
  const [searchedDepartmentSection, setSearchedDepartmentSection] =
    useState<DepartmentSection | null>(null);
  const [searchedMonth, setSearchedMonth] = useState<number | null>(null);
  const [searchedYear, setSearchedYear] = useState<number | null>(null);
  const [searchedStatus, setSearchedStatus] = useState<ScheduleStatus | null>(
    null,
  );
  const [clickedSchedule, setClickedSchedule] = useState<Schedule | undefined>(
    undefined,
  );
  const [showNewDialog, setShowNewDialog] = useState(false);
  const [sortCriteria, setSortCriteria] = useState<SortCriteria<Schedule>>({
    fieldName: 'audit',
    asc: false,
  });
  const [scheduleToDownload, setScheduleToDownload] = useState<
    Schedule | undefined
  >(undefined);
  const [downloadPopoverAnchor, setDownloadPopoverAnchor] =
    useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    setPagination({
      ...pagination,
      pageNumber: 0,
    });
  }, [
    searchedInstitution,
    searchedDepartment,
    searchedDepartmentSection,
    searchedName,
    searchedYear,
    searchedMonth,
  ]);

  useEffect(() => {
    setSearchedDepartmentSection(null);
  }, [searchedDepartment]);

  useEffect(() => {
    setSearchedDepartment(null);
  }, [searchedInstitution]);

  const showDownloadPopover = Boolean(downloadPopoverAnchor);
  const downloadPopoverID = showDownloadPopover
    ? 'download-popover'
    : undefined;

  const handleSearch = (e: any) => {
    setSearchedName(e.target.value ?? '');
  };

  const prepareGetRequest = (): ScheduleGetRequest => {
    const request: ScheduleGetRequest = {
      pagination,
      sort: getScheduleSortRequest(sortCriteria),
      filter: {},
    };

    if (searchedName !== '') {
      request.filter!.planName = searchedName;
    }

    if (searchedInstitution) {
      request.filter!.institutionID = searchedInstitution.ID;
    }

    if (searchedDepartment) {
      request.filter!.departmentName = searchedDepartment.name;
    }

    if (searchedDepartmentSection) {
      request.filter!.sectionName = searchedDepartmentSection.name;
    }

    if (isFinite(searchedMonth)) {
      request.filter!.month = searchedMonth! + 1;
    }

    if (searchedYear) {
      request.filter!.year = searchedYear;
    }

    if (searchedStatus) {
      request.filter!.scheduleStatus = searchedStatus;
    }

    return request;
  };

  const applyFilters = () => {
    const p = {
      ...pagination,
      pageNumber: 0,
    };

    setPagination(p);

    const request: ScheduleGetRequest = {
      ...prepareGetRequest(),
      pagination: p,
    };

    setGetRequest(request);

    props.refreshSchedules(request);
  };

  const handleModifySchedule = () => {
    setShowNewDialog(false);
    setClickedSchedule(undefined);
    props.onChange(pagination);
  };

  const handleCloseUpsertDialog = () => {
    setClickedSchedule(undefined);
    setShowNewDialog(false);
  };

  const handleClickDownload = (
    e: React.MouseEvent<HTMLButtonElement>,
    newScheduleToDownload: Schedule,
  ) => {
    setDownloadPopoverAnchor(e.currentTarget);
    setScheduleToDownload(newScheduleToDownload);
  };

  const handleCloseDownloadPopover = () => {
    setDownloadPopoverAnchor(null);
    setScheduleToDownload(undefined);
  };

  const handleFileExport = () => {
    if (!scheduleToDownload || !isFinite(scheduleToDownload.ID)) {
      return;
    }

    setLoading(true);
    downloadScheduleExcel(scheduleToDownload.ID!)
      .then(() => toast.success(t('shared.download_successful')))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleSort = (fieldName: keyof Schedule) => {
    const p = {
      pageSize: pagination.pageSize,
      pageNumber: 0,
    };

    setPagination(p);

    const criteria: SortCriteria<Schedule> = {
      fieldName,
      asc: fieldName === sortCriteria.fieldName ? !sortCriteria.asc : true,
    };

    setSortCriteria(criteria);

    const request: ScheduleGetRequest = {
      ...getRequest,
      pagination: p,
      sort: getScheduleSortRequest(criteria),
    };

    setGetRequest(request);

    props.refreshSchedules(request);
  };

  const handleRowsPerPageChange = (e: any) => {
    const p = {
      pageNumber: 0,
      pageSize: parseInt(e.target.value, 10),
    };

    setPagination(p);

    const request: ScheduleGetRequest = { ...getRequest, pagination: p };

    setGetRequest(request);

    props.refreshSchedules(request);
  };

  const handlePageChange = (_: any, pageNumber: number) => {
    const p = {
      pageSize: pagination.pageSize,
      pageNumber,
    };

    setPagination(p);

    const request: ScheduleGetRequest = { ...getRequest, pagination: p };

    setGetRequest(request);

    props.refreshSchedules(request);
  };

  const nonEmptyInstitutions = props.institutions.filter(
    (institution) => !isEmpty(institution.departments),
  );

  const readOnly = !hasWriteAccess(tokenData, AccessLevel.DEPARTMENT_SECTION);

  return (
    <Box component='div' sx={sharedStyles.outerTableContainer}>
      <Waiting open={loading} />
      {!isEmpty(nonEmptyInstitutions) && !readOnly && (
        <InsertScheduleDialog
          open={showNewDialog}
          onClose={handleCloseUpsertDialog}
          onUpsert={handleModifySchedule}
          institutions={nonEmptyInstitutions}
          departments={props.departments}
          schedules={props.scheduleList.schedules}
          scheduleSummaries={props.scheduleSummaries}
        />
      )}
      <UpdateScheduleDialog
        scheduleSummaries={props.scheduleSummaries}
        onClose={handleCloseUpsertDialog}
        onModify={handleModifySchedule}
        clickedSchedule={clickedSchedule}
      />
      <Typography
        sx={{
          ...sharedStyles.h5,
          color: sharedColors.black,
          fontWeight: 450,
        }}
      >
        {t('schedule.title')}
      </Typography>
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'row',
          mt: 1.5,
        }}
      >
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
        >
          <Box
            component='div'
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <InstitutionAutocomplete
              label={t('schedule.institution') as string}
              width={250}
              options={props.institutions}
              value={searchedInstitution}
              onChange={setSearchedInstitution}
              backgroundColor={sharedColors.white}
            />
            <Box component='div' sx={{ mr: 1.5 }} />
            <DepartmentAutocomplete
              label={t('schedule.department') as string}
              options={searchedInstitution?.departments ?? []}
              disabled={!searchedInstitution}
              value={searchedDepartment}
              onChange={setSearchedDepartment}
              width={216}
              backgroundColor={sharedColors.white}
            />
            <Box component='div' sx={{ mr: 1.5 }} />
            <DepartmentSectionAutocomplete
              label={t('schedule.department_section') as string}
              options={searchedDepartment?.departmentSections ?? []}
              value={searchedDepartmentSection}
              disabled={!searchedDepartment}
              onChange={setSearchedDepartmentSection}
              width={215}
              backgroundColor={sharedColors.white}
            />
          </Box>
          <Box
            component='div'
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'row',
              flexWrap: 'wrap',
              mt: 1.5,
            }}
          >
            <TextField
              id='search-text-field'
              size='small'
              label={t('schedule.name')}
              variant='outlined'
              value={searchedName}
              onChange={handleSearch}
              sx={{ width: 200, backgroundColor: sharedColors.white, mr: 1.5 }}
            />
            <YearDropdown
              label={t('shared.year') as string}
              width={120}
              backgroundColor={sharedColors.white}
              allowSelectAll
              defaultToCurrentYear
              options={extractYearsFromDateRanges(
                props.scheduleList.schedules.map((schedule) =>
                  getScheduleDateRange(schedule),
                ),
              )}
              value={searchedYear}
              onChange={setSearchedYear}
            />
            <Box component='div' sx={{ mr: 1.5 }} />
            <MonthDropdown
              label={t('shared.month') as string}
              width={150}
              backgroundColor={sharedColors.white}
              allowSelectAll
              value={searchedMonth}
              onChange={setSearchedMonth}
            />
            <Box component='div' sx={{ mr: 1.5 }} />
            <ScheduleStatusDropdown
              label={t('schedule.status.header') as string}
              width={200}
              backgroundColor={sharedColors.white}
              value={searchedStatus}
              onChange={setSearchedStatus}
            />
          </Box>
        </Box>
        <Box component='div' sx={{ mr: 1.5 }} />
        <Button
          color='primary'
          endIcon={<ChevronRight />}
          onClick={applyFilters}
          sx={{ ...sharedStyles.buttonText, mt: 'auto', mb: 'auto' }}
        >
          {t('shared.apply_filter')}
        </Button>
        <Box component='div' sx={{ flexGrow: 1 }} />
        {!readOnly && (
          <Button
            variant='contained'
            color='primary'
            onClick={() => setShowNewDialog(true)}
            sx={{ ...sharedStyles.buttonText, mt: 'auto', mb: 0 }}
          >
            {t('schedule.new')}
          </Button>
        )}
      </Box>
      <TableContainer component={Paper} sx={sharedStyles.tableContainer}>
        <Table size='small'>
          <TableHead sx={{ backgroundColor: sharedColors.gray2 }}>
            <TableRow>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='name'
                onSort={() => handleSort('name')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('schedule.name')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='institutionName'
                onSort={() => handleSort('institutionName')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('schedule.institution')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='department'
                onSort={() => handleSort('department')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('schedule.department')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='departmentSection'
                onSort={() => handleSort('departmentSection')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('schedule.department_section')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='startAt'
                onSort={() => handleSort('startAt')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('shared.start_at')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='endAt'
                onSort={() => handleSort('endAt')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('shared.end_at')}
                </Typography>
              </SortableTableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('schedule.stats.personnel_count')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('schedule.stats.total_hours')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('schedule.stats.total_overtime_hours')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('audit.last_updated_by')}
                </Typography>
              </TableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='audit'
                onSort={() => handleSort('audit')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('audit.last_updated_at')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='status'
                onSort={() => handleSort('status')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('schedule.status.header')}
                </Typography>
              </SortableTableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('shared.download')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.scheduleList.schedules.map((row) => {
              const isClickable =
                row.status !== ScheduleStatus.QUEUED &&
                row.status !== ScheduleStatus.RUNNING;

              return (
                <TableRow key={row.ID} sx={sharedStyles.editableRow}>
                  <TableCell
                    onClick={
                      isClickable ? () => setClickedSchedule(row) : undefined
                    }
                  >
                    <Typography sx={sharedStyles.tableStringField}>
                      {row.name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    onClick={
                      isClickable ? () => setClickedSchedule(row) : undefined
                    }
                  >
                    <Typography sx={sharedStyles.tableStringField}>
                      {row.institutionName}
                    </Typography>
                  </TableCell>
                  <TableCell
                    onClick={
                      isClickable ? () => setClickedSchedule(row) : undefined
                    }
                  >
                    <Typography sx={sharedStyles.tableStringField}>
                      {row.department.name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    onClick={
                      isClickable ? () => setClickedSchedule(row) : undefined
                    }
                  >
                    <Typography sx={sharedStyles.tableStringField}>
                      {row.departmentSection.name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    onClick={
                      isClickable ? () => setClickedSchedule(row) : undefined
                    }
                  >
                    <Typography sx={sharedStyles.tableStringField}>
                      {formatSingleDay(t, row.startAt)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    onClick={
                      isClickable ? () => setClickedSchedule(row) : undefined
                    }
                  >
                    <Typography sx={sharedStyles.tableStringField}>
                      {formatSingleDay(t, row.endAt)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    onClick={
                      isClickable ? () => setClickedSchedule(row) : undefined
                    }
                  >
                    <Typography sx={sharedStyles.tableStringField}>
                      {Object.keys(row.plan).length}
                    </Typography>
                  </TableCell>
                  <TableCell
                    onClick={
                      isClickable ? () => setClickedSchedule(row) : undefined
                    }
                  >
                    <Typography sx={sharedStyles.tableStringField}>
                      {t('shared.n_hours', {
                        n: Math.ceil(row.totalStats.totalWorkedHours),
                      })}
                    </Typography>
                  </TableCell>
                  <TableCell
                    onClick={
                      isClickable ? () => setClickedSchedule(row) : undefined
                    }
                  >
                    <Typography sx={sharedStyles.tableStringField}>
                      {t('shared.n_hours', {
                        n: Math.ceil(row.totalStats.totalOvertimeHours),
                      })}
                    </Typography>
                  </TableCell>
                  <TableCell
                    onClick={
                      isClickable ? () => setClickedSchedule(row) : undefined
                    }
                  >
                    <Typography sx={sharedStyles.tableStringField}>
                      {row.audit.lastUpdatedBy ?? row.audit.createdBy}
                    </Typography>
                  </TableCell>
                  <TableCell
                    onClick={
                      isClickable ? () => setClickedSchedule(row) : undefined
                    }
                  >
                    <Typography sx={sharedStyles.tableStringField}>
                      {formatLocalTime(
                        t,
                        row.audit.lastUpdatedAt ?? row.audit.createdAt,
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    onClick={
                      isClickable ? () => setClickedSchedule(row) : undefined
                    }
                  >
                    <ScheduleStatusField scheduleStatus={row.status} />
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={(e) => handleClickDownload(e, row)}
                      disabled={!isClickable}
                      sx={{ ...sharedStyles.buttonText, p: 0, minWidth: 0 }}
                    >
                      {t('shared.download')}
                    </Button>
                    <DownloadMenu
                      id={downloadPopoverID}
                      open={showDownloadPopover}
                      anchorEl={downloadPopoverAnchor}
                      onClose={handleCloseDownloadPopover}
                      onDownload={handleFileExport}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          count={props.scheduleList.totalCount}
          page={pagination.pageNumber}
          onPageChange={handlePageChange}
          rowsPerPage={pagination.pageSize}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[5, 10, 15, 25, 50]}
          component='div'
          labelRowsPerPage={t('shared.pagination.rows_per_page')}
          labelDisplayedRows={({ from, to, count }) =>
            t('shared.pagination.from_to_count', { from, to, count })
          }
        />
      </TableContainer>
    </Box>
  );
};

export default ScheduleTable;
