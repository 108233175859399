export interface Personnel {
  ID?: number;
  personnelID: string;
  name: string;
  position: PersonnelPosition;
  canBeOnCall: boolean;
  is4d: boolean;
  notes: string;
  dayShiftHours: number;
  institutionID: number;
  institutionName: string;
  departmentID: number;
  departmentSectionID?: number;
  departmentName: string;
  departmentSectionName?: string;
}

export interface PersonnelUpsert extends Personnel {
  firstName: string;
  lastName: string;
}

export enum PersonnelPosition {
  ALL = '__ALL__',
  SENIOR_NURSE = 'SENIOR_NURSE',
  HEAD_NURSE = 'HEAD_NURSE',
  NURSE = 'NURSE',
}

export enum FourDType {
  TYPE1 = 'TYPE1',
  TYPE2 = 'TYPE2',
  TYPE3 = 'TYPE3',
}

export const personnelFromResponse = (responseData: any): Personnel => ({
  ID: responseData.id,
  personnelID: responseData.personnel_id,
  name: responseData.name,
  position: responseData.position,
  canBeOnCall: responseData.can_be_on_call,
  is4d: responseData.is_4d,
  notes: responseData.notes,
  dayShiftHours: responseData.day_shift_hours,
  institutionID: responseData.institution_id,
  institutionName: responseData.institution_name,
  departmentID: responseData.department_id,
  departmentSectionID: responseData.department_section_id,
  departmentName: responseData.department_name,
  departmentSectionName: responseData.department_section_name,
});

export const personnelOutputTransformer = (personnel: Personnel) => ({
  id: personnel.ID,
  name: personnel.name,
  personnel_id: personnel.personnelID,
  position: personnel.position,
  can_be_on_call: personnel.canBeOnCall,
  is_4d: personnel.is4d,
  notes: personnel.notes,
  day_shift_hours: personnel.dayShiftHours,
  institution_id: personnel.institutionID,
  institution_name: personnel.institutionName,
  department_id: personnel.departmentID,
  department_section_id: personnel.departmentSectionID ?? null,
  department_name: personnel.departmentName,
  department_section_name: personnel.departmentSectionName ?? null,
});
