import { isArray } from 'lodash';
import {
  PersonnelPerformance,
  personnelPerformanceFromResponse,
  PersonnelPerformanceRequest,
  personnelPerformanceRequestOutputTransformer,
} from '../interfaces/PersonnelPerformance';
import api from './Api';

const personnelPerformanceBaseUrl = '/personnel-performance/';

export const getPersonnelPerformances = async (
  req: PersonnelPerformanceRequest,
): Promise<PersonnelPerformance[]> => {
  const response = await api.get(personnelPerformanceBaseUrl, {
    params: personnelPerformanceRequestOutputTransformer(req),
  });

  if (!isArray(response.data)) {
    throw new Error('Unexpected response: Response data is not an array');
  }

  return response.data.map((p) => personnelPerformanceFromResponse(p));
};
