import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Paper,
  Switch,
  TableContainer,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { toast } from 'material-react-toastify';
import { cloneDeep, isEmpty, isFinite } from 'lodash';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import { useRecoilValue } from 'recoil';
import Waiting from '../Waiting';
import { sharedColors, sharedStyles } from '../../utils/Style';
import {
  Schedule,
  ScheduleStatus,
  ScheduleSummary,
} from '../../interfaces/Schedule';
import {
  combineSchedules,
  deleteSchedule,
  updateSchedule,
} from '../../services/Schedule';
import {
  formatSingleDay,
  getAllDatesBetweenTwoDate,
  isHoliday,
  isWorkday,
} from '../../utils/Date';
import {
  compareSchedulePersonnelNames,
  getScheduleTemplate,
  getWorkedHours,
  getWorkedHoursByDate,
  hoursStringFromShift,
} from '../../utils/Schedule';
import ConfirmDialog from '../ConfirmDialog';
import { FullDayDates } from '../../interfaces/FullDayDates';
import DepartmentSettingsTooltip from '../Department/DepartmentSettingsTooltip';
import { tokenDataAtom } from '../../atoms/TokenData';
import { hasWriteAccess } from '../../utils/TokenData';
import { AccessLevel } from '../../interfaces/User';
import RestoreScheduleDialog from './RestoreScheduleDialog';
import FullDayOptionsTooltip from './FullDayOptionsTooltip';
import { PtoSubtype } from '../../interfaces/PtoSubtype';
import AddScheduleNotesDialog from './AddScheduleNotesDialog';
import { Shift } from '../../interfaces/Shift';
import ScheduleNotesDialog from './ScheduleNotesDialog';
import ScheduleApprovalCard from './ScheduleApprovalCard';
import ScheduleApprovalRollbackCard from './ScheduleApprovalRollbackCard';
import { PersonnelCountsByDay } from '../../interfaces/PersonnelCounts';
import {
  getMinimumPersonnelCountsByDay,
  getWorkingPersonnelCountsByDay,
} from '../../utils/PersonnelCounts';
import { RotationType } from '../../utils/enums/RotationType';
import ScheduleGridCell from './ScheduleGridCell';
import {
  getFullDayMultiplierLabelFromValue,
  getOvertimeMultiplierLabelFromValue,
} from '../../interfaces/AlgorithmParameterMultipliers';
import { ScheduleEndEarlyDialog } from './ScheduleEndEarlyDialog';
import { ScheduleCombineDialog } from './ScheduleCombineDialog';

interface UpdateScheduleDialogProps {
  clickedSchedule?: Schedule;
  scheduleSummaries: ScheduleSummary[];
  onClose: () => void;
  onModify: () => void;
}

const UpdateScheduleDialog = (props: UpdateScheduleDialogProps) => {
  const { t } = useTranslation();

  const tokenData = useRecoilValue(tokenDataAtom);

  const [loading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState<Schedule>(
    getScheduleTemplate(),
  );
  const [minPersonnelCounts, setMinPersonnelCounts] =
    useState<PersonnelCountsByDay>({});
  const [clickedDelete, setClickedDelete] = useState(false);
  const [clickedUpdate, setClickedUpdate] = useState(false);
  const [showRestoreDialog, setShowRestoreDialog] = useState(false);
  const [showNotesDialog, setShowNotesDialog] = useState(false);
  const [showEndEarlyDialog, setShowEndEarlyDialog] = useState(false);
  const [showCombineScheduleDialog, setShowCombineScheduleDialog] =
    useState(false);

  const readOnly =
    !hasWriteAccess(tokenData, AccessLevel.DEPARTMENT_SECTION) ||
    currentState.status === ScheduleStatus.PENDING_SUPERVISOR ||
    currentState.status === ScheduleStatus.APPROVED;

  useEffect(() => {
    setCurrentState(props.clickedSchedule ?? getScheduleTemplate());
    setMinPersonnelCounts(
      props.clickedSchedule
        ? getMinimumPersonnelCountsByDay(props.clickedSchedule)
        : {},
    );
    setShowRestoreDialog(false);
  }, [props.clickedSchedule]);

  const handleChangeName = (e: any) => {
    setCurrentState({ ...currentState, name: e.target.value });
  };

  const handleUpdate = (notes: string, files: File[]) => {
    setClickedUpdate(false);
    setLoading(true);

    updateSchedule(currentState, notes, files)
      .then(() => {
        toast.success(t('schedule.successful.update'));
        props.onModify();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleEndEarly = (endDate: Date, notes: string) => {
    setShowEndEarlyDialog(false);
    setLoading(true);

    const endDateString = endDate.toISOString();

    const plan = cloneDeep(currentState.plan);
    const overtimeHours = cloneDeep(currentState.overtimeHours);
    const ptoSubtypes = cloneDeep(currentState.ptoSubtypes);

    Object.entries(currentState.plan).forEach(([personnel, day]) => {
      Object.entries(day).forEach(([dayDate, _]) => {
        if (dayDate > endDateString) {
          // @ts-ignore
          delete plan[personnel][dayDate];
        }
      });
    });

    Object.entries(currentState.overtimeHours).forEach(([personnel, day]) => {
      Object.entries(day).forEach(([dayDate, _]) => {
        if (dayDate > endDateString) {
          delete overtimeHours[personnel][dayDate];
        }
      });
    });

    Object.entries(currentState.ptoSubtypes).forEach(([personnel, day]) => {
      Object.entries(day).forEach(([dayDate, _]) => {
        if (dayDate > endDateString) {
          delete ptoSubtypes[personnel][dayDate];
        }
      });
    });

    updateSchedule(
      {
        ...props.clickedSchedule!,
        plan,
        overtimeHours,
        ptoSubtypes,
        endAt: endDate,
      },
      notes,
      [],
    )
      .then(() => {
        toast.success(t('schedule.successful.update'));
        props.onModify();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleCombineSchedules = (
    id: number,
    keepOldPlans: boolean,
    name: string,
  ) => {
    setShowCombineScheduleDialog(false);
    setLoading(true);

    combineSchedules(id, currentState.ID!, keepOldPlans, name)
      .then(() => {
        toast.success('Planlar birleştirildi');
        props.onModify();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleClickUpdate = () => {
    if (
      JSON.stringify(currentState.plan) !==
        JSON.stringify(props.clickedSchedule?.plan ?? {}) ||
      JSON.stringify(currentState.overtimeHours) !==
        JSON.stringify(props.clickedSchedule?.overtimeHours ?? {})
    ) {
      setClickedUpdate(true);
    } else {
      handleUpdate('', []);
    }
  };

  const handleConfirmDelete = () => {
    if (!isFinite(props.clickedSchedule?.ID)) {
      return;
    }

    setLoading(true);

    deleteSchedule(props.clickedSchedule!.ID!)
      .then(() => {
        toast.success(t('schedule.successful.delete'));
        setClickedDelete(false);
        props.onModify();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleOvertimeChange = (
    personnelName: string,
    dateStr: string,
    increment: number,
  ) => {
    const personnelOvertimeHours =
      currentState.overtimeHours[personnelName] ?? {};
    let overtimeHours = personnelOvertimeHours[dateStr] ?? 0;
    overtimeHours += increment;
    personnelOvertimeHours[dateStr] = overtimeHours;

    setCurrentState({
      ...currentState,
      overtimeHours: {
        ...currentState.overtimeHours,
        [personnelName]: personnelOvertimeHours,
      },
    });
  };

  const getColumnColorForDay = (day: Date): string => {
    if (!isWorkday(day)) return 'gray3';

    return isHoliday(day, currentState.fullDayDates) ? 'gray4' : 'gray1';
  };

  const dates = getAllDatesBetweenTwoDate(
    currentState.startAt,
    currentState.endAt,
  );

  const hasAdministrativeHours = currentState.administrativeHours !== 0;
  const workingPersonnelCounts = getWorkingPersonnelCountsByDay(currentState);

  const datesColumns: GridColDef[] = dates.map((dateDay) => {
    const dateStr = DateTime.fromJSDate(dateDay).toISODate()!;

    const isDayWorkday = isWorkday(dateDay);

    const minPersonnels = minPersonnelCounts[dateStr] ?? { day: 0, night: 0 };
    const workingPersonnels = workingPersonnelCounts[dateStr] ?? {
      day: 0,
      night: 0,
    };
    const personnelCountWarnings: string[] = [];
    if (
      workingPersonnels.day < minPersonnels.day ||
      (isDayWorkday && workingPersonnels.day > minPersonnels.day + 1) ||
      (!isDayWorkday && workingPersonnels.day > minPersonnels.day)
    ) {
      personnelCountWarnings.push(
        t('schedule.warning.personnel_count', {
          shift: t('schedule.shifts.DAY'),
          expected: isDayWorkday ? minPersonnels.day + 1 : minPersonnels.day,
          actual: workingPersonnels.day,
        }),
      );
    }
    if (workingPersonnels.night !== minPersonnels.night) {
      personnelCountWarnings.push(
        t('schedule.warning.personnel_count', {
          shift: t('schedule.shifts.NIGHT'),
          expected: minPersonnels.night,
          actual: workingPersonnels.night,
        }),
      );
    }

    return {
      field: dateStr,
      headerName: dateDay.toLocaleDateString('en-GB'),
      flex: 1,
      editable: !readOnly,
      minWidth: 100,
      sortable: false,
      type: 'singleSelect',
      valueOptions: [
        ...[
          Shift.EMPTY,
          Shift.DAY,
          Shift.NIGHT,
          Shift.ALL_DAY,
          Shift.EIGHT_HOUR_NIGHT,
          Shift.SIXTEEN_HOUR_DAY,
        ].map((shift) => ({
          value: shift,
          label: hoursStringFromShift(
            currentState,
            undefined,
            undefined,
            shift,
          ),
        })),
        ...Object.keys(PtoSubtype).map((ptoSubtype) => ({
          value: ptoSubtype,
          label: t(`pto_subtype.name.${ptoSubtype}`),
        })),
      ],
      renderCell: (params: GridRenderCellParams) => (
        <ScheduleGridCell
          dateDay={dateDay}
          dateStr={dateStr}
          schedule={currentState}
          personnelCountWarnings={personnelCountWarnings}
          params={params}
          editable={!readOnly}
          onOvertimeHoursChange={(increment) =>
            handleOvertimeChange(params.id as string, dateStr, increment)
          }
        />
      ),
      cellClassName: () => getColumnColorForDay(dateDay),
      headerClassName: () => getColumnColorForDay(dateDay),
    };
  });

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('personnel.name')!,
      flex: 1,
      editable: false,
      sortable: false,
      minWidth: 170,
    },
    {
      field: 'status',
      headerName: 'Statü',
      flex: 1,
      editable: false,
      sortable: false,
      minWidth: 90,
    },
    ...datesColumns,
    ...(hasAdministrativeHours
      ? [
          {
            field: 'administrativeHours',
            headerName: t('schedule.administrative_hours') as string,
            flex: 1,
            editable: false,
            sortable: false,
            minWidth: 150,
          },
        ]
      : []),
    {
      field: 'totalWorkedHours',
      headerName: t('schedule.total_hours') as string,
      flex: 1,
      editable: false,
      sortable: false,
      minWidth: 150,
    },
    {
      field: 'totalHoursShouldWorked',
      headerName: t('schedule.total_hours_should_worked') as string,
      flex: 1,
      editable: false,
      sortable: false,
      minWidth: 200,
    },
    {
      field: 'totalOvertimeHours',
      headerName: t('schedule.overtime_hours') as string,
      flex: 1,
      editable: false,
      sortable: false,
      minWidth: 150,
    },
  ];

  const getTotalHoursRow = (totalOvertimeHours: number) => {
    const totalHours: Map<string, number> = getWorkedHoursByDate(currentState);

    const row = {
      id: t('shared.total'),
      name: t('shared.total'),
      administrativeHours:
        currentState.administrativeHours *
        Object.keys(currentState.plan).length,
      totalOvertimeHours,
    };
    Object.keys(totalHours).forEach((date: string) => {
      // @ts-ignore
      row[date] = totalHours[date];
    });
    return row;
  };

  const onRowEditCommit = (newRow: any, oldRow: any) => {
    let changedDate: string = '';
    Object.keys(newRow).forEach((key: string) => {
      if (newRow[key] !== oldRow[key]) {
        changedDate = key;
        return true;
      }
      return false;
    });

    const plan = cloneDeep(currentState.plan);
    const overtimeHours = cloneDeep(currentState.overtimeHours);
    const ptoSubtypes = cloneDeep(currentState.ptoSubtypes);

    const shift = newRow[changedDate];
    if (shift === Shift.EMPTY) {
      // @ts-ignore
      delete plan[newRow.name][changedDate];
      delete ptoSubtypes[newRow.name][changedDate];
    } else if (shift === PtoSubtype.SPECIAL_REQUEST) {
      // @ts-ignore
      plan[newRow.name][changedDate] = Shift.ETO;
      ptoSubtypes[newRow.name][changedDate] = PtoSubtype.SPECIAL_REQUEST;
    } else if (Object.keys(PtoSubtype).includes(shift)) {
      // @ts-ignore
      plan[newRow.name][changedDate] = Shift.PTO;
      ptoSubtypes[newRow.name][changedDate] = shift;
    } else {
      // @ts-ignore
      plan[newRow.name][changedDate] = shift;
    }

    const personnelOvertimeHours = overtimeHours[newRow.name] ?? {};
    personnelOvertimeHours[changedDate] = 0;
    overtimeHours[newRow.name] = personnelOvertimeHours;

    // @ts-ignore
    const personnelPlan = plan[newRow.name];

    const hoursWorked = getWorkedHours(
      currentState,
      newRow.name,
      personnelPlan,
      currentState.startAt,
      currentState.endAt,
      currentState.fullDayDates,
      currentState.fourDPersonnelNames,
    );

    // eslint-disable-next-line no-param-reassign
    newRow.totalWorkedHours = hoursWorked.totalWorkedHours;
    // eslint-disable-next-line no-param-reassign
    newRow.totalHoursShouldWorked = hoursWorked.totalHoursShouldWorked;
    // eslint-disable-next-line no-param-reassign
    newRow.totalOvertimeHours = hoursWorked.totalOvertimeHours;

    setCurrentState({ ...currentState, plan, overtimeHours, ptoSubtypes });
    return newRow;
  };

  let totalOvertimeHours = 0;
  const personnelRows = Object.keys(currentState.plan)
    .sort((n1, n2) =>
      compareSchedulePersonnelNames(
        n1,
        n2,
        currentState.departmentSection.personnels,
      ),
    )
    .map((name) => {
      // @ts-ignore
      const personnelPlan = currentState.plan[name];
      const hoursWorked = getWorkedHours(
        currentState,
        name,
        personnelPlan,
        currentState.startAt,
        currentState.endAt,
        currentState.fullDayDates,
        currentState.fourDPersonnelNames,
      );

      const row = {
        id: name,
        status: props.clickedSchedule?.fourDPersonnelNames.includes(name)
          ? '4D'
          : 'Normal',
        name,
        administrativeHours: currentState.administrativeHours,
        totalWorkedHours: hoursWorked.totalWorkedHours,
        totalHoursShouldWorked: hoursWorked.totalHoursShouldWorked,
        totalOvertimeHours: hoursWorked.totalOvertimeHours,
      };

      totalOvertimeHours += hoursWorked.totalOvertimeHours;

      dates.forEach((date: Date) => {
        const dateStr = DateTime.fromJSDate(date).toISODate()!;

        let shift = personnelPlan[dateStr];
        if (shift === Shift.PTO || shift === Shift.ETO) {
          const personnelSubtypes = currentState.ptoSubtypes[name] ?? {};
          shift =
            personnelSubtypes[dateStr] ??
            (shift === Shift.PTO
              ? PtoSubtype.ANNUAL
              : PtoSubtype.SPECIAL_REQUEST);
        }

        // @ts-ignore
        row[dateStr] = shift;
      });

      return row;
    });

  const rows: GridRowsProp = [
    ...personnelRows,
    getTotalHoursRow(totalOvertimeHours),
  ];

  const isExpired = props.clickedSchedule
    ? new Date() > props.clickedSchedule.endAt
    : true;

  return (
    <Dialog
      open={!!props.clickedSchedule}
      onClose={props.onClose}
      maxWidth={'xl'}
    >
      {isFinite(currentState.ID) && (
        <RestoreScheduleDialog
          scheduleID={currentState.ID!}
          open={showRestoreDialog}
          onRollback={props.onModify}
          onClose={() => setShowRestoreDialog(false)}
        />
      )}
      {showEndEarlyDialog && (
        <ScheduleEndEarlyDialog
          startAt={currentState.startAt}
          endAt={currentState.endAt}
          onCancel={() => setShowEndEarlyDialog(false)}
          onClose={(d: Date, n: string) => handleEndEarly(d, n)}
        />
      )}
      {showCombineScheduleDialog && (
        <ScheduleCombineDialog
          scheduleSummaries={props.scheduleSummaries}
          schedule={currentState}
          onCancel={() => setShowCombineScheduleDialog(false)}
          onClose={(id: number, b: boolean, n: string) =>
            handleCombineSchedules(id, b, n)
          }
        />
      )}
      <ScheduleNotesDialog
        open={showNotesDialog}
        scheduleID={currentState.ID ?? -1}
        onComment={() =>
          setCurrentState({
            ...currentState,
            scheduleNotesCount: currentState.scheduleNotesCount + 1,
          })
        }
        onClose={() => setShowNotesDialog(false)}
      />
      <AddScheduleNotesDialog
        title={t('schedule.update_notes.title') as string}
        content={t('schedule.update_notes.description') as string}
        open={clickedUpdate}
        onClose={() => setClickedUpdate(false)}
        onConfirm={handleUpdate}
      />
      <ConfirmDialog
        content={t('schedule.delete.confirm_message')}
        open={clickedDelete}
        onClose={() => setClickedDelete(false)}
        onConfirm={handleConfirmDelete}
        color='error'
      />
      <Waiting open={loading} />
      <DialogTitle sx={{ ...sharedStyles.h6, color: sharedColors.gray6 }}>
        {t('schedule.dialog.update')}
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'row', mt: 1.5 }}
        >
          <TextField
            label={t('schedule.name')}
            value={currentState.name}
            disabled={readOnly}
            onChange={handleChangeName}
            size='small'
            error={isEmpty(currentState.name)}
            sx={{ width: 200, mr: 1.5 }}
          />
          <Tooltip title={currentState.institutionName}>
            <TextField
              label={t('schedule.institution')}
              value={currentState.institutionName}
              disabled={true}
              size='small'
              sx={{ width: 200, mr: 1.5 }}
            />
          </Tooltip>
          <TextField
            label={t('schedule.department')}
            value={currentState.department.name}
            disabled={true}
            size='small'
            sx={{ width: 200, mr: 1.5 }}
          />
          <TextField
            label={t('schedule.department_section')}
            value={currentState.departmentSection.name}
            disabled={true}
            size='small'
            sx={{ width: 200 }}
          />
        </Box>
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'row', mt: 1.5 }}
        >
          <TextField
            label={t('shared.start_at')}
            value={formatSingleDay(t, currentState.startAt)}
            disabled={true}
            size='small'
            sx={{ width: 160, mr: 1.5 }}
          />
          <TextField
            label={t('shared.end_at')}
            value={formatSingleDay(t, currentState.endAt)}
            disabled={true}
            size='small'
            sx={{ width: 160, mr: 1.5 }}
          />
          <TextField
            label={t('schedule.weekday_rotation_type')}
            value={
              currentState.weekdayRotationType === RotationType.FULL_DAY
                ? t('schedule.all_day_rotation')
                : t('schedule.day_night_rotation')
            }
            disabled={true}
            size='small'
            sx={{ width: 160, mr: 1.5 }}
          />
          <TextField
            label={t('schedule.weekend_rotation_type')}
            value={
              currentState.weekendRotationType === RotationType.FULL_DAY
                ? t('schedule.all_day_rotation')
                : t('schedule.day_night_rotation')
            }
            disabled={true}
            size='small'
            sx={{ width: 160, mr: 1.5 }}
          />
          <TextField
            label={t('schedule.previous_schedule')}
            value={
              currentState.previousScheduleName
                ? currentState.previousScheduleName
                : t('shared.not_exist')
            }
            disabled={true}
            size='small'
            sx={{ width: 160, mr: 1.5 }}
          />
          <Button
            color='primary'
            variant='outlined'
            onClick={() => setShowNotesDialog(true)}
            sx={{ ...sharedStyles.buttonText, mr: 1.5 }}
          >
            {t('schedule.update_notes.notes_button', {
              n: currentState.scheduleNotesCount.toString(),
            })}
          </Button>
          <DepartmentSettingsTooltip
            departmentSettings={currentState.departmentSettings}
          >
            <Button
              color='primary'
              endIcon={<InfoIcon />}
              sx={{
                ...sharedStyles.buttonText,
                textAlign: 'left',
                justifyContent: 'left',
                p: 0,
              }}
            >
              {t('schedule.personnel_constraints')}
            </Button>
          </DepartmentSettingsTooltip>
          <Tooltip
            title={
              <Typography>
                {t('schedule.overtime_hours_equality')}:
                {getOvertimeMultiplierLabelFromValue(
                  currentState.algorithmParameterMultipliers.overtimeHours,
                )}
                <br />
                {t('schedule.non_fullday_night_punish')}:
                {getFullDayMultiplierLabelFromValue(
                  currentState.algorithmParameterMultipliers.nonFullDayNight,
                )}
                <br />
                {t('schedule.fullday_night_reward')}:
                {getFullDayMultiplierLabelFromValue(
                  currentState.algorithmParameterMultipliers.fullDayNight,
                )}
                <br />
              </Typography>
            }
          >
            <Button
              color='primary'
              endIcon={<InfoIcon />}
              sx={{
                ...sharedStyles.buttonText,
                textAlign: 'left',
                justifyContent: 'left',
                p: 0,
                ml: 2,
              }}
            >
              {t('schedule.advanced_settings')}
            </Button>
          </Tooltip>
        </Box>
        {currentState.fullDayDates.length > 0 && (
          <Box
            component='div'
            sx={{ display: 'flex', flexDirection: 'column', mt: 1.5 }}
          >
            <Typography sx={sharedStyles.h7}>
              {t('schedule.all_day_dates')}
            </Typography>

            {currentState.fullDayDates.map((fullDays: FullDayDates) => (
              <Box
                component='div'
                sx={{ display: 'flex', flexDirection: 'row', mt: 1.5 }}
              >
                <TextField
                  label={t('shared.start_at')}
                  value={formatSingleDay(t, fullDays.startAt)}
                  disabled={true}
                  size='small'
                  sx={{ width: 200, mr: 1.5 }}
                />
                <TextField
                  label={t('shared.end_at')}
                  value={formatSingleDay(t, fullDays.endAt)}
                  disabled={true}
                  size='small'
                  sx={{ width: 200, mr: 1.5 }}
                />
                <TextField
                  label={t('schedule.name')}
                  value={fullDays.name}
                  disabled={true}
                  size='small'
                  sx={{ width: 200, mr: 1.5 }}
                />
                <FormControlLabel
                  control={
                    <Switch
                      size='small'
                      disabled={true}
                      checked={fullDays.isOfficialHoliday}
                      color='primary'
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      sx={{ mr: 1.5 }}
                    />
                  }
                  label={
                    <Typography sx={{ ...sharedStyles.subtitle2 }}>
                      {t('schedule.is_official_holiday')}
                    </Typography>
                  }
                />
                <TextField
                  label={t('schedule.rotation_type')}
                  value={
                    fullDays.rotationType === RotationType.FULL_DAY
                      ? t('schedule.all_day_rotation')
                      : t('schedule.day_night_rotation')
                  }
                  disabled={true}
                  size='small'
                  sx={{ width: 160, mr: 1.5 }}
                />
                <FullDayOptionsTooltip
                  personnels={currentState.departmentSection.personnels}
                  fullDay={fullDays}
                >
                  <Button
                    color='primary'
                    endIcon={<InfoIcon />}
                    sx={{
                      ...sharedStyles.buttonText,
                      textAlign: 'left',
                      justifyContent: 'left',
                      p: 0,
                    }}
                  >
                    {t('schedule.all_day_personnel_constraints')}
                  </Button>
                </FullDayOptionsTooltip>
              </Box>
            ))}
          </Box>
        )}
        <ScheduleApprovalCard
          schedule={currentState}
          onChange={props.onModify}
        />
        <ScheduleApprovalRollbackCard
          schedule={currentState}
          onChange={props.onModify}
        />
        <TableContainer component={Paper} sx={sharedStyles.tableContainer}>
          <DataGrid
            sx={{
              '& .gray1': {
                backgroundColor: sharedColors.gray1,
              },
              '& .gray3': {
                backgroundColor: sharedColors.gray3,
              },
              '& .gray4': {
                backgroundColor: sharedColors.gray4,
              },
            }}
            columns={columns}
            rows={rows}
            editMode='cell'
            hideFooter
            disableColumnMenu
            showCellVerticalBorder
            showColumnVerticalBorder
            isCellEditable={(params) =>
              params.row.id !== t('shared.total') && !isExpired
            }
            processRowUpdate={onRowEditCommit}
          />
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ px: 2.5, pb: 1.5 }}>
        {!readOnly && (
          <Button
            variant='contained'
            color='error'
            onClick={() => setClickedDelete(true)}
            sx={sharedStyles.buttonText}
          >
            {t('shared.delete')}
          </Button>
        )}
        <Box component='div' sx={{ display: 'flex', flexGrow: 1 }} />
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          sx={sharedStyles.buttonText}
        >
          {t('shared.cancel')}
        </Button>
        {!isExpired && (
          <Button
            variant='contained'
            color='primary'
            disabled={!hasWriteAccess(tokenData, AccessLevel.DEPARTMENT)}
            onClick={() => setShowEndEarlyDialog(true)}
            sx={sharedStyles.buttonText}
          >
            Planı Sonlandır
          </Button>
        )}
        {!isExpired && (
          <Button
            variant='contained'
            color='primary'
            disabled={!hasWriteAccess(tokenData, AccessLevel.DEPARTMENT)}
            onClick={() => setShowCombineScheduleDialog(true)}
            sx={sharedStyles.buttonText}
          >
            Plan Birleştir
          </Button>
        )}
        {!readOnly && (
          <Button
            variant='contained'
            color='primary'
            disabled={!currentState.isModified}
            onClick={() => setShowRestoreDialog(true)}
            sx={sharedStyles.buttonText}
          >
            {t('schedule.restore.button_text')}
          </Button>
        )}
        <Button
          variant='contained'
          color='primary'
          disabled={isEmpty(currentState.name) || readOnly}
          onClick={handleClickUpdate}
          sx={sharedStyles.buttonText}
        >
          {t('schedule.dialog.update')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateScheduleDialog;
