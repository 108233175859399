import { useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { Box } from '@mui/material';
import { selectedPageAtom } from '../../atoms/Page';
import { Institution } from '../../interfaces/Institution';
import { Department } from '../../interfaces/Department';
import {
  PersonnelPerformance,
  PersonnelPerformanceRequest,
} from '../../interfaces/PersonnelPerformance';
import { PageType } from '../../interfaces/Page';
import { getInstitutions } from '../../services/Institution';
import { getDepartments } from '../../services/Department';
import { getPersonnelPerformances } from '../../services/PersonnelPerformance';
import Waiting from '../Waiting';
import { sharedStyles } from '../../utils/Style';
import PersonnelPerformanceTable from './PersonnelPerformanceTable';

const PersonnelPerformancePage = () => {
  const setSelectedPage = useSetRecoilState(selectedPageAtom);

  const [loadingPerformances, setLoadingPerformances] = useState(false);
  const [loadingInstitutions, setLoadingInstitutions] = useState(false);
  const [loadingDepartments, setLoadingDepartments] = useState(false);
  const [institutions, setInstitutions] = useState<Institution[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [performances, setPerformances] = useState<PersonnelPerformance[]>([]);

  useEffect(() => {
    setSelectedPage(PageType.PERSONNEL_PERFORMANCES);

    setLoadingInstitutions(true);
    getInstitutions()
      .then((fetchedInstitutions) => setInstitutions(fetchedInstitutions))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingInstitutions(false));

    setLoadingDepartments(true);
    getDepartments()
      .then((fetchedDepartments) => setDepartments(fetchedDepartments))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingDepartments(false));
  }, []);

  const handleSearch = (filters: PersonnelPerformanceRequest) => {
    setLoadingPerformances(true);
    getPersonnelPerformances(filters)
      .then((fetchedPerformances) => setPerformances(fetchedPerformances))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingPerformances(false));
  };

  const loading =
    loadingInstitutions || loadingDepartments || loadingPerformances;

  return (
    <Box component='div'>
      <Waiting open={loading} />
      <Box component='div' sx={sharedStyles.pageBody}>
        <PersonnelPerformanceTable
          performances={performances}
          institutions={institutions}
          departments={departments}
          onFilter={handleSearch}
        />
      </Box>
    </Box>
  );
};

export default PersonnelPerformancePage;
