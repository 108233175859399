import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ChevronRight } from '@mui/icons-material';
import {
  PersonnelPerformance,
  PersonnelPerformanceRequest,
} from '../../interfaces/PersonnelPerformance';
import { Institution } from '../../interfaces/Institution';
import { Department } from '../../interfaces/Department';
import { Pagination } from '../../interfaces/Pagination';
import { DepartmentSection } from '../../interfaces/DepartmentSection';
import { SortCriteria } from '../../interfaces/SortCriteria';
import { getPriorityByOrder } from '../../utils/Sort';
import { comparePersonnelPerformance } from '../../utils/PersonnelPerformance';
import { sharedColors, sharedStyles } from '../../utils/Style';
import { getDatePickerLocale } from '../../utils/Date';
import InstitutionAutocomplete from '../Institution/InstitutionAutocomplete';
import DepartmentAutocomplete from '../Department/DepartmentAutocomplete';
import DepartmentSectionAutocomplete from '../DepartmentSection/DepartmentSectionAutocomplete';
import SortableTableCell from '../SortableTableCell';

interface PersonnelPerformanceTableProps {
  performances: PersonnelPerformance[];
  institutions: Institution[];
  departments: Department[];
  onFilter: (req: PersonnelPerformanceRequest) => void;
}

const PersonnelPerformanceTable = (props: PersonnelPerformanceTableProps) => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState<Pagination>({
    pageNumber: 0,
    pageSize: 15,
  });
  const [startAt, setStartAt] = useState(
    DateTime.now().minus({ month: 1 }).toJSDate(),
  );
  const [endAt, setEndAt] = useState(new Date());
  const [searchedInstitution, setSearchedInstitution] =
    useState<Institution | null>(null);
  const [searchedDepartment, setSearchedDepartment] =
    useState<Department | null>(null);
  const [searchedDepartmentSection, setSearchedDepartmentSection] =
    useState<DepartmentSection | null>(null);

  const [sortCriteria, setSortCriteria] = useState<
    SortCriteria<PersonnelPerformance>
  >({
    fieldName: 'name',
    asc: true,
  });

  useEffect(() => {
    setPagination({ ...pagination, pageNumber: 0 });
  }, [props.performances]);

  useEffect(() => {
    setSearchedDepartment(null);
  }, [searchedInstitution]);

  useEffect(() => {
    setSearchedDepartmentSection(null);
  }, [searchedDepartment]);

  const handleSort = (fieldName: keyof PersonnelPerformance) => {
    setSortCriteria({
      fieldName,
      asc: fieldName === sortCriteria.fieldName ? !sortCriteria.asc : true,
    });
  };

  const handleSearch = () => {
    props.onFilter({
      startAt,
      endAt,
      institutionID: searchedInstitution?.ID,
      departmentID: searchedDepartment?.ID,
      departmentSectionID: searchedDepartmentSection?.ID,
    });
  };

  let filteredRows = props.performances;
  filteredRows = filteredRows.sort((p1, p2) =>
    comparePersonnelPerformance(p1, p2, sortCriteria.fieldName) < 0
      ? getPriorityByOrder(sortCriteria.asc)
      : -getPriorityByOrder(sortCriteria.asc),
  );

  const paginatedRows = filteredRows.slice(
    pagination.pageNumber * pagination.pageSize,
    (pagination.pageNumber + 1) * pagination.pageSize,
  );

  return (
    <Box component='div' sx={sharedStyles.outerTableContainer}>
      <Typography
        sx={{
          ...sharedStyles.h5,
          color: sharedColors.black,
          fontWeight: 450,
        }}
      >
        {t('personnel_performances.title')}
      </Typography>

      <Box
        component='div'
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'row',
          flexWrap: 'wrap',
          mt: 1.5,
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={getDatePickerLocale()}
        >
          <DatePicker
            label={t('personnel_performances.start_at')}
            value={startAt}
            inputFormat='d MMMM yyyy'
            onChange={(newValue: Date | null) =>
              setStartAt(newValue ?? new Date())
            }
            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
              <TextField
                {...params}
                size='small'
                sx={{
                  width: 175,
                  backgroundColor: sharedColors.white,
                }}
              />
            )}
          />
        </LocalizationProvider>

        <Box component='div' sx={{ mr: 1.5 }} />

        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={getDatePickerLocale()}
        >
          <DatePicker
            label={t('personnel_performances.end_at')}
            value={endAt}
            inputFormat='d MMMM yyyy'
            onChange={(newValue: Date | null) =>
              setEndAt(newValue ?? new Date())
            }
            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
              <TextField
                {...params}
                size='small'
                sx={{
                  width: 175,
                  backgroundColor: sharedColors.white,
                }}
              />
            )}
          />
        </LocalizationProvider>

        <Box component='div' sx={{ mr: 1.5, ml: 1.5 }}>
          <InstitutionAutocomplete
            label={t('personnel_performances.institution') as string}
            width={200}
            options={props.institutions}
            value={searchedInstitution}
            onChange={setSearchedInstitution}
            backgroundColor={sharedColors.white}
          />
        </Box>

        <DepartmentAutocomplete
          label={t('personnel_performances.department') as string}
          disabled={!searchedInstitution}
          options={props.departments.filter(
            (department) =>
              !!searchedInstitution &&
              department.institutionID === searchedInstitution.ID,
          )}
          value={searchedDepartment}
          onChange={setSearchedDepartment}
          width={200}
          backgroundColor={sharedColors.white}
        />

        <Box component='div' sx={{ mr: 1.5 }} />

        <DepartmentSectionAutocomplete
          label={t('personnel_performances.department_section') as string}
          options={searchedDepartment?.departmentSections ?? []}
          value={searchedDepartmentSection}
          disabled={!searchedDepartment}
          onChange={setSearchedDepartmentSection}
          width={200}
          backgroundColor={sharedColors.white}
        />

        <Button
          color='primary'
          endIcon={<ChevronRight />}
          disabled={!searchedInstitution}
          onClick={handleSearch}
          sx={{ ...sharedStyles.buttonText, mt: 'auto', mb: 'auto', ml: 1.5 }}
        >
          {t('shared.search')}
        </Button>
      </Box>
      <TableContainer component={Paper} sx={sharedStyles.tableContainer}>
        <Table size='small'>
          <TableHead sx={{ backgroundColor: sharedColors.gray2 }}>
            <TableRow>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='name'
                onSort={() => handleSort('name')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('personnel_performances.name')}
                </Typography>
              </SortableTableCell>

              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='institutionName'
                onSort={() => handleSort('institutionName')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('personnel_performances.institution')}
                </Typography>
              </SortableTableCell>

              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='departmentName'
                onSort={() => handleSort('departmentName')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('personnel_performances.department')}
                </Typography>
              </SortableTableCell>

              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='departmentSectionName'
                onSort={() => handleSort('departmentSectionName')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('personnel_performances.department_section')}
                </Typography>
              </SortableTableCell>

              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='totalOvertime'
                onSort={() => handleSort('totalOvertime')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('personnel_performances.total_overtime')}
                </Typography>
              </SortableTableCell>

              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='totalPtoHours'
                onSort={() => handleSort('totalPtoHours')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('personnel_performances.total_pto')}
                </Typography>
              </SortableTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((row, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.institutionName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.departmentName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.departmentSectionName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.totalOvertime.toString()}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.totalPtoHours.toString()}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={filteredRows.length}
          page={pagination.pageNumber}
          onPageChange={(_, newPage) =>
            setPagination({ ...pagination, pageNumber: newPage })
          }
          rowsPerPage={pagination.pageSize}
          onRowsPerPageChange={(e) =>
            setPagination({
              pageNumber: 0,
              pageSize: parseInt(e.target.value, 10),
            })
          }
          rowsPerPageOptions={[5, 10, 15, 25, 50]}
          component='div'
          labelRowsPerPage={t('shared.pagination.rows_per_page')}
          labelDisplayedRows={({ from, to, count }) =>
            t('shared.pagination.from_to_count', { from, to, count })
          }
        />
      </TableContainer>
    </Box>
  );
};

export default PersonnelPerformanceTable;
