import { DateTime } from 'luxon';
import { Department, departmentFromResponse } from './Department';
import { FourDType, Personnel, PersonnelPosition } from './Personnel';
import {
  DepartmentSection,
  departmentSectionFromResponse,
} from './DepartmentSection';
import { Audit, auditFromResponse } from './Audit';
import {
  FullDayDates,
  fullDayDatesFromResponse,
  fullDayDatesOutputTransformer,
} from './FullDayDates';
import { DateRange } from './DateRange';
import { PtoSubtype } from './PtoSubtype';
import {
  OvertimeChangesMap,
  overtimeChangesMapFromResponse,
  ShiftChangesMap,
  shiftChangesMapFromResponse,
} from './ShiftChangeNotes';
import { Shift } from './Shift';
import { Pagination } from './Pagination';
import { RotationType } from '../utils/enums/RotationType';
import {
  AlgorithmParameterMultipliers,
  algorithmParameterMultipliersFromResponse,
  algorithmParameterMultipliersOutputTransformer,
} from './AlgorithmParameterMultipliers';

export interface ScheduleStats {
  totalPtoHours: number;
  totalWorkedHours: number;
  totalHoursShouldWorked: number;
  totalOvertimeHours: number;
}

export interface Schedule {
  ID?: number;
  name: string;
  startAt: Date;
  endAt: Date;
  institutionID: number;
  institutionName: string;
  department: Department;
  departmentSection: DepartmentSection;
  plan: Map<string, Map<string, Shift>>;
  overtimeHours: { [key: string]: { [key: string]: number } };
  isModified: boolean;
  shiftHours: { [key: string]: ShiftHours };
  ptoSubtypes: { [key: string]: { [key: string]: PtoSubtype } };
  shiftChanges: ShiftChangesMap;
  overtimeChanges: OvertimeChangesMap;
  scheduleNotesCount: number;
  administrativeHours: number;
  previousScheduleName?: string;
  totalStats: ScheduleStats;
  status: ScheduleStatus;
  audit: Audit;
  fullDayDates: FullDayDates[];
  departmentSettings: { [key: string]: number };
  weekendRotationType: RotationType;
  weekdayRotationType: RotationType;
  algorithmParameterMultipliers: AlgorithmParameterMultipliers;
  fourDPersonnelNames: string[];
}

export enum ScheduleStatus {
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
  REJECTED = 'REJECTED',
  PENDING_SUPERVISOR = 'PENDING_SUPERVISOR',
  APPROVED = 'APPROVED',
}

export interface TimeOffRange extends DateRange {
  ptoSubtype: PtoSubtype;
}

export interface SchedulePersonnel {
  ID: number;
  name: string;
  position: PersonnelPosition;
  dayShiftHours: number;
  workAtAllWorkdayDayShifts: boolean;
  canBeOnCall: boolean;
  is4d: boolean;
  fourDType: FourDType;
  unavailableDays: TimeOffRange[];
}

export interface AlgorithmParameters {
  [key: string]: number;
}

export interface ScheduleInsertRequest {
  name: string;
  startAt: Date;
  endAt: Date;
  institutionID: number;
  departmentID: number;
  departmentSectionID: number;
  maxOvertimeHours: number;
  personnels: SchedulePersonnel[];
  previousScheduleID?: number;
  previousScheduleName?: string;
  algorithmParameters: AlgorithmParameters;
  algorithmParameterMultipliers: AlgorithmParameterMultipliers;
  fullDayDates: FullDayDates[];
  weekendRotationType: RotationType;
  weekdayRotationType: RotationType;
  departmentSettings: { [key: string]: number };
  administrativeHours: number;
}

export interface ScheduleSortCriteria {
  sortFieldName: ScheduleSortableFields;
  isDescending: boolean;
}

export interface ScheduleFilterRequest {
  planName?: string;
  departmentName?: string;
  sectionName?: string;
  month?: number;
  year?: number;
  institutionID?: number;
  scheduleStatus?: ScheduleStatus;
}

export interface ScheduleGetRequest {
  pagination: Pagination;
  sort: ScheduleSortCriteria;
  filter?: ScheduleFilterRequest;
}

export interface ScheduleSummary {
  ID: number;
  name: string;
  departmentSectionID: number;
  startAt: Date;
  endAt: Date;
  audit: Audit;
}

export enum ScheduleSortableFields {
  NAME = 'NAME',
  INSTITUTION = 'INSTITUTION',
  DEPARTMENT = 'DEPARTMENT',
  SECTION = 'SECTION',
  START_AT = 'START_AT',
  END_AT = 'END_AT',
  LAST_UPDATED_AT = 'LAST_UPDATED_AT',
  STATUS = 'STATUS',
}

export interface WorkedHours {
  totalWorkedHours: number;
  totalHoursShouldWorked: number;
  totalOvertimeHours: number;
}

export type ShiftHours = {
  [key in Shift]: number;
};

export interface ScheduleList {
  schedules: Schedule[];
  totalCount: number;
}

export const scheduleStatsFromResponse = (
  responseData: any,
): ScheduleStats => ({
  totalPtoHours: responseData.total_pto_hours,
  totalWorkedHours: responseData.total_worked_hours,
  totalHoursShouldWorked: responseData.total_hours_should_worked,
  totalOvertimeHours: responseData.total_overtime_hours,
});

export const scheduleFromResponse = (responseData: any): Schedule => ({
  ID: responseData.id,
  name: responseData.name,
  startAt: new Date(responseData.start_at),
  endAt: new Date(responseData.end_at),
  institutionID: responseData.institution_id,
  institutionName: responseData.institution_name,
  department: departmentFromResponse(responseData.department),
  departmentSection: departmentSectionFromResponse(
    responseData.department_section,
  ),
  weekendRotationType: responseData.weekend_rotation_type,
  weekdayRotationType: responseData.weekday_rotation_type,
  plan: responseData.plan,
  overtimeHours: responseData.overtime_hours,
  isModified: responseData.is_modified,
  shiftHours: responseData.shift_hours,
  ptoSubtypes: responseData.pto_subtypes,
  shiftChanges: shiftChangesMapFromResponse(responseData.shift_changes),
  overtimeChanges: overtimeChangesMapFromResponse(
    responseData.overtime_changes,
  ),
  scheduleNotesCount: responseData.schedule_notes_count,
  administrativeHours: responseData.administrative_hours,
  totalStats: scheduleStatsFromResponse(responseData.total_stats),
  previousScheduleName: responseData.previous_schedule_name,
  status: responseData.status,
  audit: auditFromResponse(responseData.audit),
  fullDayDates: responseData.full_day_dates.map((d: any) =>
    fullDayDatesFromResponse(d),
  ),
  departmentSettings: responseData.department_settings,
  algorithmParameterMultipliers: algorithmParameterMultipliersFromResponse(
    responseData.algorithm_parameter_multipliers,
  ),
  fourDPersonnelNames: responseData.four_d_personnel_names,
});

export const scheduleListFromResponse = (responseData: any): ScheduleList => ({
  totalCount: responseData.total_count,
  schedules: responseData.schedules.map((schedule: any) =>
    scheduleFromResponse(schedule),
  ),
});

export const scheduleSummaryFromResponse = (
  responseData: any,
): ScheduleSummary => ({
  name: responseData.name,
  ID: responseData.id,
  departmentSectionID: responseData.department_section_id,
  audit: auditFromResponse(responseData.audit),
  startAt: new Date(responseData.start_at),
  endAt: new Date(responseData.end_at),
});

export const scheduleOutputTransformer = (schedule: Schedule) => ({
  id: schedule.ID,
  name: schedule.name,
  department_id: schedule.department.ID,
  department_section_id: schedule.departmentSection.ID,
  start_at: DateTime.fromJSDate(schedule.startAt).toISODate(),
  end_at: DateTime.fromJSDate(schedule.endAt).toISODate(),
  plan: schedule.plan,
  overtime_hours: schedule.overtimeHours,
  shift_hours: schedule.shiftHours,
  pto_subtypes: schedule.ptoSubtypes,
  administrative_hours: schedule.administrativeHours,
  status: schedule.status,
});

export const scheduleInsertRequestOutputTransformer = (
  schedule: ScheduleInsertRequest,
) => ({
  name: schedule.name,
  institution_id: schedule.institutionID,
  department_id: schedule.departmentID,
  department_section_id: schedule.departmentSectionID,
  start_at: DateTime.fromJSDate(schedule.startAt).toISODate(),
  end_at: DateTime.fromJSDate(schedule.endAt).toISODate(),
  max_overtime_hours: schedule.maxOvertimeHours,
  personnels: schedule.personnels.map((personnel: SchedulePersonnel) =>
    schedulePersonnelOutputTransformer(personnel),
  ),
  previous_schedule_id: schedule.previousScheduleID ?? null,
  previous_schedule_name: schedule.previousScheduleName ?? null,
  algorithm_parameters:
    calculateAlgorithmParameters(
      schedule.algorithmParameters,
      schedule.algorithmParameterMultipliers,
    ) ?? {},
  algorithm_parameter_multipliers:
    algorithmParameterMultipliersOutputTransformer(
      schedule.algorithmParameterMultipliers,
    ),
  full_day_dates: schedule.fullDayDates.map((d: FullDayDates) =>
    fullDayDatesOutputTransformer(d),
  ),
  weekend_rotation_type: schedule.weekendRotationType,
  weekday_rotation_type: schedule.weekdayRotationType,
  department_settings: schedule.departmentSettings,
  administrative_hours: schedule.administrativeHours,
});

export const schedulePersonnelOutputTransformer = (
  personnel: SchedulePersonnel,
) => ({
  id: personnel.ID,
  name: personnel.name,
  position: personnel.position,
  day_shift_hours: personnel.dayShiftHours,
  work_at_all_workday_day_shifts: personnel.workAtAllWorkdayDayShifts,
  can_be_on_call: personnel.canBeOnCall,
  is_4d: personnel.is4d,
  four_d_type: personnel.is4d ? personnel.fourDType : undefined,
  unavailable_days: personnel.unavailableDays
    .filter((range) => !!range.endAt && !!range.endAt)
    .map((range) => {
      return {
        start_at: DateTime.fromJSDate(range.startAt).toISODate(),
        end_at: DateTime.fromJSDate(range.endAt).toISODate(),
        pto_subtype: range.ptoSubtype,
      };
    }),
});

export const schedulePersonnelFromPersonnel = (
  personnel: Personnel,
): SchedulePersonnel => ({
  ID: personnel.ID!,
  name: personnel.name,
  position: personnel.position,
  dayShiftHours: personnel.dayShiftHours,
  is4d: personnel.is4d,
  fourDType: FourDType.TYPE1,
  workAtAllWorkdayDayShifts:
    personnel.position === PersonnelPosition.HEAD_NURSE,
  canBeOnCall: personnel.canBeOnCall,
  unavailableDays: [],
});

export const calculateAlgorithmParameters = (
  parameters: AlgorithmParameters,
  multipliers: AlgorithmParameterMultipliers,
) => ({
  ...parameters,
  overtime_range_weight:
    parameters.overtime_range_weight * multipliers.overtimeHours,
  overtime_stdev_weight:
    parameters.overtime_stdev_weight * multipliers.overtimeHours,
  non_fullday_night: parameters.non_fullday_night * multipliers.nonFullDayNight,
  fullday_night: parameters.fullday_night * multipliers.fullDayNight,
});
