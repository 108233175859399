import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  TextFieldProps,
} from '@mui/material';
import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { sharedStyles } from '../../utils/Style';
import { getDatePickerLocale } from '../../utils/Date';

interface ScheduleEndEarlyDialogProps {
  endAt: Date;
  startAt: Date;
  onClose: (d: Date, n: string) => void;
  onCancel: () => void;
}

export const ScheduleEndEarlyDialog = (props: ScheduleEndEarlyDialogProps) => {
  const { t } = useTranslation();

  const [endAt, setEndAt] = useState<Date>(props.endAt);
  const [notes, setNotes] = useState<string>('');

  return (
    <Dialog open={true} onClose={props.onCancel}>
      <DialogTitle>Planı Bitir</DialogTitle>
      <DialogContent>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={getDatePickerLocale()}
        >
          <DatePicker
            label={t('shared.end_at')}
            value={endAt}
            maxDate={props.endAt}
            minDate={props.startAt}
            inputFormat='d MMMM yyyy'
            onChange={(newValue: Date | null) =>
              setEndAt(newValue === null ? endAt : newValue)
            }
            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
              <TextField
                {...params}
                size='small'
                sx={{
                  width: 200,
                  mt: 1.5,
                }}
              />
            )}
          />
          <TextField
            label={t('personnel.notes')}
            value={notes}
            onChange={(e) => setNotes(e.target?.value ?? '')}
            size='small'
            fullWidth
            multiline
            sx={{ mt: 1.5 }}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onCancel}
          sx={sharedStyles.buttonText}
        >
          {t('shared.cancel')}
        </Button>
        <Button
          variant='contained'
          onClick={() => props.onClose(endAt, notes)}
          color='primary'
          sx={sharedStyles.buttonText}
        >
          Planı Bitir
        </Button>
      </DialogActions>
    </Dialog>
  );
};
